import React from 'react';

export default function About_us(){

   return(
      
      <div className="About_us">
         
         <div className="header">NR GOLD LIMITED</div>
         <div className="About_us_desc">
            <p>Sanjay Jain, the owner of NR GOLD LIMITED located in Mumbai, has an impressive 30-year experience in the wholesale and manufacturing of gold ornaments. 
               Our company specializes in the B2B market and operates through our branch office in Vijaywada, Andhra Pradesh.</p>
            <p>Discover the captivating collection of pan-Indian jewelry, particularly renowned for its exquisite South Indian traditional designs, 
               offered exclusively by NR GOLD LIMITED.
               Our jewelry is designed to leave a lasting impression, offering a perfect fusion of tradition, quality, and Indian culture in every stunning jewelry piece.</p>
            <p>At our brand, we take pride in presenting a unique combination of fashion and design. Each creation is meticulously crafted with creativity and skilled craftsmanship, 
               ensuring every detail is perfected. We also offer emerald items, as shown in our product images too.</p>
         </div>
         
      </div>
      
   )
}