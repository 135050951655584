import logo from './images/Logo.png'
import logo_text from './images/Logo_text.png'


export default function Navbar(){
   
   let lastscroll=0
   let is_on_tooltip=false
   let tooltip_element=document.getElementsByClassName("Products_tooltip_child")
   let lock=false
   let timeout
   let colorIndex=0
   let currentColor=["rgb(255, 153, 0)","rgb(3, 170, 3)","rgb(0, 150, 255)"]
   let initialrotation=120
   let tooltip_state=false
  
  window.onscroll= function myfunc(){
   if(lock===false){
    let scroll=window.scrollY
    
    if(scroll>=9300){
      document.querySelector(".Navbar_temp").classList.add("Navbar_hidden")
      is_on_tooltip=false
      hide_tooltip()
      
    }
    else if((scroll<lastscroll)||(scroll>=-500 && scroll<=50)){
      document.querySelector(".Navbar_temp").classList.remove("Navbar_hidden")
  }
    else if(scroll>lastscroll){
      document.querySelector(".Navbar_temp").classList.add("Navbar_hidden")   
      is_on_tooltip=false
      hide_tooltip()
    }
  lastscroll=scroll
   }}
 
 function show_tooltip(){
   for(let i=0;i<12;i++){
      tooltip_element[i].style.cursor="pointer"
      tooltip_element[i].style.pointerEvents="all"}
   document.querySelector(".Products_tooltip").style.opacity=1
 }
 
 function hide_tooltip(){
   if(is_on_tooltip===false) {
      for(let i=0;i<12;i++){
         tooltip_element[i].style.cursor="default"
         tooltip_element[i].style.pointerEvents="none"}
   document.querySelector(".Products_tooltip").style.opacity=0
}
 }
 
 
function scroll_to_element(element){
   clearInterval(timeout)
   lock=true
   let height=document.querySelector(`.${element}`).getBoundingClientRect()
   let Nav_height=document.querySelector(".Navbar").clientHeight
   height=height.top.toFixed()
   window.scrollBy(0,height-Nav_height)
   timeout=setTimeout(function(){lock=false},1000)
} 

function changeColor(){
   if(colorIndex<2){
      colorIndex++
   }
   else{colorIndex=0}
   
   document.body.style.color=currentColor[colorIndex]
   document.querySelector(".Navbar_temp").style.backgroundColor=currentColor[colorIndex]
   document.querySelector(".Navbar_perm").style.backgroundColor=currentColor[colorIndex]
   document.querySelector(".Products_tooltip").style.color=currentColor[colorIndex]
   document.querySelector(".Products_tooltip").style.borderColor=currentColor[colorIndex]
   document.querySelector(".left_slider").style.color=currentColor[colorIndex]
   document.querySelector(".right_slider").style.color=currentColor[colorIndex]
   document.querySelector(".dots1").style.backgroundColor=currentColor[colorIndex]
   document.querySelector(".dots2").style.backgroundColor=currentColor[colorIndex]
   document.querySelector(".dots3").style.backgroundColor=currentColor[colorIndex]
   document.querySelector(".dots0").style.backgroundColor=currentColor[colorIndex]
   document.querySelector(".contact_us").style.backgroundColor=currentColor[colorIndex]
   document.querySelector(".logo").style.transform=`rotate(${initialrotation}deg)`
   initialrotation=initialrotation+120
}

   return(
      <div className="Navbar">
         
         
         <div className="Navbar_perm">
            <div className="logo_div"><img src={logo} alt="NR GOLD logo" className="logo" onClick={changeColor}/></div>
         </div>
         
         <div className="Navbar_temp">
            <div className="homeComponent_container" onClick={function(){
               scroll_to_element("homeComponent")}}>Home</div>
            <div className="ProductsComponent_container_container">
               <div className="Products">
               <div className="Products_text" onClick={function(){scroll_to_element("ProductsComponent_container")}}>Products</div>
               <div className="Products_arrowhead" onMouseEnter={show_tooltip} onMouseLeave={function(){setTimeout(hide_tooltip,200)}} onClick={
                  function(){if(tooltip_state===false){show_tooltip()
                     tooltip_state=true}
                  else{hide_tooltip()
                     tooltip_state=false}}}>&#x25BC;</div>
               </div>
               <div className="Products_tooltip" onMouseEnter={function(){is_on_tooltip=true}} onMouseLeave={function(){is_on_tooltip=false 
                  hide_tooltip()}}>
                  <div className="Products_tooltip_child" onClick={function(){scroll_to_element("short_ms")}}>Short Mangalsutra</div>
                  <div className="Products_tooltip_child" onClick={function(){scroll_to_element("long_ms")}}>Long Mangalsutra</div>
                  <div className="Products_tooltip_child" onClick={function(){scroll_to_element("vertical_mala")}}>Vertical mala</div>
                  <div className="Products_tooltip_child" onClick={function(){scroll_to_element("haram")}}>Haram</div>
                  <div className="Products_tooltip_child" onClick={function(){scroll_to_element("necklace")}}>Necklace</div>
                  <div className="Products_tooltip_child" onClick={function(){scroll_to_element("bombay_necklace")}}>Bombay Necklace</div>
                  <div className="Products_tooltip_child" onClick={function(){scroll_to_element("bracelet")}}>Bracelet</div>
                  <div className="Products_tooltip_child" onClick={function(){scroll_to_element("singapore_bracelet")}}>Singapore Bracelet</div>
                  <div className="Products_tooltip_child" onClick={function(){scroll_to_element("hanging")}}>Hanging</div>
                  <div className="Products_tooltip_child" onClick={function(){scroll_to_element("jumki")}}>Jumki</div>
                  <div className="Products_tooltip_child" onClick={function(){scroll_to_element("champasaralu")}}>Champasaralu</div>
                  <div className="Products_tooltip_child" onClick={function(){scroll_to_element("chokker")}}>Chokker</div>
               </div>   
            </div>
            <div className="logo_text_container"><img src={logo_text} alt="NR GOLD logo text" className="logo_text" /></div>
            <div className="About_us_container" onClick={function(){scroll_to_element("About_us")}}>About us </div>
            <div className="contact_us_container" onClick={function(){scroll_to_element("contact_us")}}>Contact us</div>
         </div>
      
      
      </div>
      
   )
}