import React from 'react';
import './Style.css';
import Navbar from './Navbar';
import HomeComponenet from './Home'
import ProductsComponent from './Products'
import AboutUs from './About_us'
import ContactUs from './Contact_us'


export default function App() {
  
  
  return (
    <div className="App">
      
      <div className="Navbar_container"><Navbar/></div>
      <div className="Home_container"><HomeComponenet/></div>
      <div className="Products_container"><ProductsComponent/></div>
      <div className="Aboutus_container"><AboutUs/></div>
      <div className="Contactus_container"><ContactUs/></div>
      
    </div>
  );
}



