
import img1 from './images/slideshow_img_1.jpeg';
import img2 from './images/slideshow_img_2.jpeg';
import img3 from './images/slideshow_img_3.jpeg';
import img4 from './images/slideshow_img_4.jpeg';
import React from 'react';


export default function Home(){
   
   const slides=[img1,img2,img3,img4]
   const [currentSlide, setcurrentSlide] = React.useState(0)
   const timer= React.useRef(null)
   
   function prev_slide(){
      const isfirstslide=currentSlide===0
      const newSlide= isfirstslide? slides.length-1: currentSlide-1
      setcurrentSlide(newSlide)
      document.querySelector(".dots0").style.opacity =0.5
      document.querySelector(".dots1").style.opacity =0.5
      document.querySelector(".dots2").style.opacity =0.5
      document.querySelector(".dots3").style.opacity =0.5
      document.querySelector(`.dots${newSlide}`).style.opacity=1
   }
   
   const next_slide= React.useCallback(function (){
      const islastslide=currentSlide===slides.length-1
      const newSlide= islastslide? 0: currentSlide+1
      setcurrentSlide(newSlide)
      document.querySelector(".dots0").style.opacity =0.5
      document.querySelector(".dots1").style.opacity =0.5
      document.querySelector(".dots2").style.opacity =0.5
      document.querySelector(".dots3").style.opacity =0.5
      document.querySelector(`.dots${newSlide}`).style.opacity=1
   },[currentSlide,slides])
   
   function go_to_slide(slideIndex){
      setcurrentSlide(slideIndex)
   }
   
   
   React.useEffect(function(){
      if(timer.current){
         clearTimeout(timer.current)
      }
      timer.current=setTimeout(next_slide,2500)
      
      return function(){
         clearTimeout(timer.current)
      }
   }, [next_slide])
return(
   <div className="homeComponent">
      
      <div className="Slideshow_container" style={{height:`${0.67*document.body.clientWidth}px`}}>
      <div className="Slideshow">
         <div className="left_slider" onClick={prev_slide}>&#10094;</div>
         <div className="right_slider" onClick={next_slide}>&#10095;</div>
         <div className='Slideshow_overlay'>
         <div className='Slideshow_img_container' style={{transform:`translateX(${-(currentSlide*document.body.clientWidth)}px)`}}>
            {slides.map(
               function(_,slideIndex){
                  return(
                     <div key={slideIndex} className="Slideshow_img" style={{backgroundImage:`url(${slides[slideIndex]})`}}></div>
                  )
               }
            )}
         </div>
         </div>
         <div className="dots_container">
            {slides.map(
               function(slide,slideIndex){
                 return(
                     <div key={slideIndex} className={`dots${slideIndex}`} onClick={function(){
                        go_to_slide(slideIndex)
                        document.querySelector(".dots0").style.opacity =0.5
                        document.querySelector(".dots1").style.opacity =0.5
                        document.querySelector(".dots2").style.opacity =0.5
                        document.querySelector(".dots3").style.opacity =0.5
                        document.querySelector(`.dots${slideIndex}`).style.opacity=1
                     }}></div>
                  )
               
                })}
         </div>
      </div>
      </div>
      
      
   </div>
)
}