import React from 'react';
import bracelet1 from './images/products/bracelet_1.jpeg';
import bracelet2 from './images/products/bracelet_2.jpeg';
import bracelet3 from './images/products/bracelet_3.jpeg';
import bracelet4 from './images/products/bracelet_4.jpeg';
import chokker1 from './images/products/chokker_1.jpeg';
import chokker2 from './images/products/chokker_2.jpeg';
import chokker3 from './images/products/chokker_3.jpeg';
import chokker4 from './images/products/chokker_4.jpeg';
import haram1 from './images/products/haram_1.jpeg';
import haram2 from './images/products/haram_2.jpeg';
import haram3 from './images/products/haram_3.jpeg';
import haram4 from './images/products/haram_4.jpeg';
import long_ms1 from './images/products/long_ms_1.jpeg';
import long_ms2 from './images/products/long_ms_2.jpeg';
import long_ms3 from './images/products/long_ms_3.jpeg';
import long_ms4 from './images/products/long_ms_4.jpeg';
import short_ms1 from './images/products/short_ms_1.jpeg';
import short_ms2 from './images/products/short_ms_2.jpeg';
import short_ms3 from './images/products/short_ms_3.jpeg';
import short_ms4 from './images/products/short_ms_4.jpeg';
import necklace1 from './images/products/necklace_1.jpeg';
import necklace2 from './images/products/necklace_2.jpeg';
import necklace3 from './images/products/necklace_3.jpeg';
import necklace4 from './images/products/necklace_4.jpeg';
import vertical_mala1 from './images/products/vertical_mala_1.jpeg';
import vertical_mala2 from './images/products/vertical_mala_2.jpeg';
import vertical_mala3 from './images/products/vertical_mala_3.jpeg';
import vertical_mala4 from './images/products/vertical_mala_4.jpeg';
import champasaralu1 from './images/products/champasaralu_1.JPG';
import champasaralu2 from './images/products/champasaralu_2.JPG';
import champasaralu3 from './images/products/champasaralu_3.JPG';
import champasaralu4 from './images/products/champasaralu_4.JPG';
import hanging1 from './images/products/hanging_1.JPG';
import hanging2 from './images/products/hanging_2.JPG';
import hanging3 from './images/products/hanging_3.JPG';
import hanging4 from './images/products/hanging_4.JPG';
import jumki1 from './images/products/jumki_1.JPG';
import jumki2 from './images/products/jumki_2.JPG';
import jumki3 from './images/products/jumki_3.JPG';
import jumki4 from './images/products/jumki_4.JPG';
import singapore_bracelet1 from './images/products/singapore_bracelet_1.jpeg';
import singapore_bracelet2 from './images/products/singapore_bracelet_2.jpeg';
import singapore_bracelet3 from './images/products/singapore_bracelet_3.jpeg';
import singapore_bracelet4 from './images/products/singapore_bracelet_4.jpeg';
import bombay_necklace1 from './images/products/bombay_necklace_1.jpeg';
import bombay_necklace2 from './images/products/bombay_necklace_2.jpeg';
import bombay_necklace3 from './images/products/bombay_necklace_3.jpeg';
import bombay_necklace4 from './images/products/bombay_necklace_4.jpeg';



export default function Products(){
   
   const bracelets=[bracelet1,bracelet2,bracelet3,bracelet4]
   const chokkers=[chokker1,chokker2,chokker3,chokker4]
   const harams=[haram1,haram2,haram3,haram4]
   const long_ms=[long_ms1,long_ms2,long_ms3,long_ms4]
   const short_ms=[short_ms1,short_ms2,short_ms3,short_ms4]
   const necklaces=[necklace1,necklace2,necklace3,necklace4]
   const vertical_malas=[vertical_mala1,vertical_mala2,vertical_mala3,vertical_mala4]
   const champasaralus=[champasaralu1,champasaralu2,champasaralu3,champasaralu4]
   const hangings=[hanging1,hanging2,hanging3,hanging4]
   const jumkis=[jumki1,jumki2,jumki3,jumki4]
   const singapore_bracelets=[singapore_bracelet1,singapore_bracelet2,singapore_bracelet3,singapore_bracelet4]
   const bombay_necklaces=[bombay_necklace1,bombay_necklace2,bombay_necklace3,bombay_necklace4]
   
   return(
      
   < div className='ProductsComponent_container'>
      <div className="header">PRODUCTS</div>
      <div className="short_ms">
         <div className="Product_head">Short Mangalsutra</div>
         <div className="Product_img_container">
         <img src={short_ms[0]} alt="product sample"/>
         <img src={short_ms[1]} alt="product sample"/>
         <img src={short_ms[2]} alt="product sample"/>
         <img src={short_ms[3]} alt="product sample"/>
         </div>
      </div>
      <div className="long_ms">
         <div className="Product_head">Long Mangalsutra</div>
         <div className="Product_img_container">
         <img src={long_ms[0]} alt="product sample"/>
         <img src={long_ms[1]} alt="product sample"/>
         <img src={long_ms[2]} alt="product sample"/>
         <img src={long_ms[3]} alt="product sample"/>
         </div>
      </div>
      <div className="vertical_mala">
         <div className="Product_head">Vertical Mala</div>
         <div className="Product_img_container">
         <img src={vertical_malas[0]} alt="product sample"/>
         <img src={vertical_malas[1]} alt="product sample"/>
         <img src={vertical_malas[2]} alt="product sample"/>
         <img src={vertical_malas[3]} alt="product sample"/>
         </div>
      </div>
      <div className="haram">
         <div className="Product_head">Haram</div>
         <div className="Product_img_container">
         <img src={harams[0]} alt="product sample"/>
         <img src={harams[1]} alt="product sample"/>
         <img src={harams[2]} alt="product sample"/>
         <img src={harams[3]} alt="product sample"/>
         </div>
      </div>
      <div className="necklace">
         <div className="Product_head">Necklace</div>
         <div className="Product_img_container">
         <img src={necklaces[0]} alt="product sample"/>
         <img src={necklaces[1]} alt="product sample"/>
         <img src={necklaces[2]} alt="product sample"/>
         <img src={necklaces[3]} alt="product sample"/>
         </div>
      </div>
      <div className="bombay_necklace">
         <div className="Product_head">Bombay Necklace</div>
         <div className="Product_img_container">
         <img src={bombay_necklaces[0]} alt="product sample"/>
         <img src={bombay_necklaces[1]} alt="product sample"/>
         <img src={bombay_necklaces[2]} alt="product sample"/>
         <img src={bombay_necklaces[3]} alt="product sample"/>
         </div>
      </div>
      <div className="bracelet">
         <div className="Product_head">Bracelet</div>
         <div className="Product_img_container">
         <img src={bracelets[0]} alt="product sample"/>
         <img src={bracelets[1]} alt="product sample"/>
         <img src={bracelets[2]} alt="product sample"/>
         <img src={bracelets[3]} alt="product sample"/>
         </div>
      </div>
      <div className="singapore_bracelet">
         <div className="Product_head">Singapore Bracelet</div>
         <div className="Product_img_container">
         <img src={singapore_bracelets[0]} alt="product sample"/>
         <img src={singapore_bracelets[1]} alt="product sample"/>
         <img src={singapore_bracelets[2]} alt="product sample"/>
         <img src={singapore_bracelets[3]} alt="product sample"/>
         </div>
      </div>
      <div className="hanging">
         <div className="Product_head">Hanging</div>
         <div className="Product_img_container">
         <img src={hangings[0]} alt="product sample"/>
         <img src={hangings[1]} alt="product sample"/>
         <img src={hangings[2]} alt="product sample"/>
         <img src={hangings[3]} alt="product sample"/>
         </div>
      </div>
      <div className="jumki">
         <div className="Product_head">Jumki</div>
         <div className="Product_img_container">
         <img src={jumkis[0]} alt="product sample"/>
         <img src={jumkis[1]} alt="product sample"/>
         <img src={jumkis[2]} alt="product sample"/>
         <img src={jumkis[3]} alt="product sample"/>
         </div>
      </div>
      <div className="champasaralu">
         <div className="Product_head">Champasaralu</div>
         <div className="Product_img_container">
         <img src={champasaralus[0]} alt="product sample"/>
         <img src={champasaralus[1]} alt="product sample"/>
         <img src={champasaralus[2]} alt="product sample"/>
         <img src={champasaralus[3]} alt="product sample"/>
         </div>
      </div>
      <div className="chokker">
         <div className="Product_head">Chokker</div>
         <div className="Product_img_container">
         <img src={chokkers[0]} alt="product sample"/>
         <img src={chokkers[1]} alt="product sample"/>
         <img src={chokkers[2]} alt="product sample"/>
         <img src={chokkers[3]} alt="product sample"/>
         </div>
      </div>
      
      
   </div>)
}