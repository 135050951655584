import React from "react";
import whatsapp_logo from "./images/whatsapp_logo.png";
import mail_logo from "./images/mail_logo.png";
import call_logo from "./images/call_logo.png";

export default function Contact_us(element){

   return(
   <div className="contact_us">
      <div className="contact_us_top">
      <div className="whatsapp">
            <img src={whatsapp_logo} alt="Whatsapp"/>
            <div className="whatsapp_no">+91 7208393689</div>
         </div>
         <div className="Phone_container">
            <img src={call_logo} alt="Phone"/>
            <div className="Phone_no">Sanjay Jain: +91 9892099455</div></div>
         <div className="gmail">
            <img src={mail_logo} alt="Gmail"/>
            <div className="gmail_id">nrgold4425@gmail.com</div>
         </div>
      </div>
      <div className="contact_us_bottom">
         <div className="mumbai_branch">
            <div className="address">18/22, Aurum, Off. No:207,<br/>
               2nd Floor, Near Cotton Exchange,<br/>
               Sheikh Memon Street,
               Kalbadevi Road, Mumbai-400 002 </div>
         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3773.5325729668257!2d72.82750047557509!3d18.952072982227158!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ce22ef92dceb%3A0x4495e3eb9e915c58!2sAurum%20Mall!5e0!3m2!1sen!2sin!4v1688457115658!5m2!1sen!2sin" 
         style={{border:"0"}}
         allowFullScreen="" 
         loading="lazy" 
         referrerPolicy="no-referrer-when-downgrade"
         title="Aurum_mall"></iframe>
         <div className="address">Tel: 2242 6258|4911 4425 LCOM:4425</div>
         </div>
         <div className="vijayawada_branch">
         <div className="address">27-14-28, 1st Floor, Manu Square,<br/>
            Rajagopalchari St., Governorpet,<br/>
            Vijayawada-520002
         </div>
         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7650.615988779868!2d80.61597699357907!3d16.510541499999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a35f002414a55c3%3A0xd4915867f37c95e5!2sN%20.%20R%20GOLD%20%26%20JEWELS%20LLP!5e0!3m2!1sen!2sin!4v1688458610351!5m2!1sen!2sin" 
         style={{border:"0"}} 
         allowFullScreen="" 
         loading="lazy" 
         referrerPolicy="no-referrer-when-downgrade"
         title="N . R GOLD & JEWELS LLP"></iframe>
         <div className="address">Tel: 0866 6635325</div>
         </div>
      </div>
   </div>
   )
}

